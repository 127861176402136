import { Fragment } from "react";
import {
  FooterContainer,
  ImageStyled,
  NavbarContainer,
  PageContainer,
  PurpleText,
  TextContainer,
  TextStyled,
} from "../components/error/styles";
import * as config from "../config/config";

function Custom404() {
  return (
    <Fragment>
      <NavbarContainer>
        <ImageStyled
          src={config.assetDomain + "/images/landing/hiredly-logo.png"}
          width={"140px"}
          alt="hiredly-logo"
        />
      </NavbarContainer>
      <PageContainer>
        <ImageStyled
          src={
            config.assetDomain + "/images/hiredly/404error-illustration.webp"
          }
          height={"320px"}
          width={"320px"}
          alt="404-error-illustration"
        />
        <TextStyled
          variant="h4"
          fontSize={"1.5625rem"}
          fontWeight={"700"}
          lineHeight={"2rem"}
        >
          Oops, this page does not exist.
        </TextStyled>
        <TextContainer>
          <TextStyled fontSize={"1rem"}>
            Sorry, the page you're looking for isn't here.
          </TextStyled>
          <TextStyled fontSize={"1rem"}>
            This can happen because of typos in the web address or outdated
            links.
          </TextStyled>
        </TextContainer>
        <TextStyled color={"rgba(0, 0, 0, 0.60)"}>
          For urgent inquiries, please contact
          <PurpleText>hello@hiredly.com</PurpleText>
        </TextStyled>
      </PageContainer>
      <FooterContainer>
        <TextStyled fontSize={"0.75rem"}>
          © Copyright 2014 - 2023 Agensi Pekerjaan Wobb Sdn. Bhd.
        </TextStyled>
      </FooterContainer>
    </Fragment>
  );
}

export default Custom404;
